<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
    rounded="sm"
    spinner-variant="primary"
  >
    <b-card>
      <b-card-header
        class="justify-content-start p-0 pb-1 mb-1 border-bottom"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="$router.back()"
        >
          <feather-icon
            class="mr-25"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <h3 class="mb-0 ml-2">
          {{ exercise.title }} Responses
        </h3>
      </b-card-header>
      <div class="d-flex filters-box mb-2">
        <div class="fl-col-1">
          <span>Filters</span>
        </div>
        <div class="fl-col-2 d-flex justify-content-start">
          <b-navbar-nav class="nav align-items-start mr-50">
            <b-nav-item-dropdown
              class="dropdown-user nomarker"
              left
              toggle-class="d-flex align-items-center dropdown-user-link"
            >
              <b-button
                :variant="filters[0].selected ? 'primary' : 'outline-primary'"
                class="d-flex align-items-center py-50 px-1"
                pill
                @click="filters[0].open=!filters[0].open"
              >
                <span>{{ filters[0].title }}</span>
                <div
                  v-if="filters[0].selected != null"
                  :class="`${filters[0].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25 rounded-pill fw-bold`"
                >
                  {{ filters[0].selected.length ? filters[0].selected.length : '1' }}
                </div>
                <div
                  v-else
                  class="text-white py-25 rounded-pill fw-bold"
                >.
                </div>
                <feather-icon
                  :icon="filters[0].open?'ChevronUpIcon':'ChevronDownIcon'"
                  class="ml-50"
                />
              </b-button>
              <div
                class="custom-search px-1 pt-1 cursor-pointer"
                @click="filters[0].selected=null"
              >
                <h6 class="text-primary">
                  -- No Filter --
                </h6>
              </div>
              <div
                class="custom-search px-1 pt-50"
              >
                <b-form-group
                  class="mb-0"
                >
                  <b-form-checkbox
                    :checked="filters[0].selected && filters[0].selected === -1"
                    @change="selectFilter(0, 'Single', -1)"
                  >
                    Partially Filled
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <div
                class="custom-search px-1 pt-50"
              >
                <b-form-group
                  class="mb-0"
                >
                  <b-form-checkbox
                    :checked="filters[0].selected && filters[0].selected === 1"
                    @change="selectFilter(0, 'Single', 1)"
                  >
                    Fully Filled
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="nav align-items-start mr-50">
            <b-nav-item-dropdown
              class="dropdown-user nomarker"
              left
              toggle-class="d-flex align-items-center dropdown-user-link"
            >
              <template #button-content>
                <b-button
                  :variant="filters[1].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="filters[1].open=!filters[1].open"
                >
                  <span>{{ filters[1].title }}</span>
                  <div
                    v-if="filters[1].selected != null"
                    :class="`${filters[1].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[1].selected.length ? filters[1].selected.length : '1' }}
                  </div>
                  <div
                    v-else
                    class="text-white py-25 rounded-pill fw-bold"
                  >.
                  </div>
                  <feather-icon
                    :icon="filters[1].open?'ChevronUpIcon':'ChevronDownIcon'"
                    class="ml-50"
                  />
                </b-button>
              </template>

              <div
                class="custom-search px-1 pt-1"
                style="cursor: pointer;"
                @click="filters[1].selected=null"
              >
                <h6 class="text-primary">
                  -- No Filter --
                </h6>
              </div>
              <div
                v-for="mentor in filterMentors"
                :key="mentor.id"
                class="custom-search px-1 pt-50"
              >
                <b-form-group
                  class="mb-0"
                >
                  <b-form-checkbox
                    :checked="filters[1].selected && filters[1].selected.includes(mentor.id)"
                    @change="selectFilter(1, 'Multiple', mentor.id)"
                  >
                    {{ mentor.users_customuser.full_name }}
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="nav align-items-start mr-50">
            <div style="padding: 0.358rem 0;">
              <b-button
                :variant="filters[2].selected ? 'primary' : 'outline-primary'"
                class="d-flex align-items-center py-50 px-1"
                pill
                @click="fetchSortScore()"
              >
                <span>{{ filters[2].title }}</span>
                <div
                  :class="`${filters[2].selected ? 'bg-white text-primary px-50' : 'text-primary'} ml-50 py-25 rounded-pill fw-bold`"
                >
                  <feather-icon
                    :icon="filters[2].selected?'XIcon':'CheckIcon'"
                  />
                </div>
              </b-button>
            </div>
          </b-navbar-nav>
          <b-navbar-nav class="nav align-items-start mr-50">
            <div style="padding: 0.358rem 0;">
              <b-button
                :variant="filters[3].selected ? 'primary' : 'outline-primary'"
                class="d-flex align-items-center py-50 px-1"
                pill
                @click="fetchSortVerdict()"
              >
                <span>{{ filters[3].title }}</span>
                <div
                  :class="`${filters[3].selected ? 'bg-white text-primary px-50' : 'text-primary'} ml-50 py-25 rounded-pill fw-bold`"
                >
                  <feather-icon
                    :icon="filters[3].selected?'XIcon':'CheckIcon'"
                  />
                </div>
              </b-button>
            </div>
          </b-navbar-nav>
          <b-navbar-nav
            v-if="filters[2].selected && filters[3].selected"
            class="nav align-items-start mr-50"
          >
            <div style="padding: 0.358rem 0;">
              <b-button
                class="d-flex align-items-center py-50 px-1"
                variant="flat-primary"
              >
                <span>{{ filters[4].title }}</span>
                <div class="d-flex align-items-center">
                  <b-form-select
                    v-model="filters[4].selected"
                    :options="[10,20,30,40,50,60,70,80,90,100]"
                    class="px-0 py-0 pl-50 text-primary"
                    style="background: none; box-shadow: none; border: none; height: auto;"
                  />
                  %
                </div>
              </b-button>
            </div>
          </b-navbar-nav>
          <b-navbar-nav class="nav align-items-start mr-50">
            <b-nav-item-dropdown
              class="dropdown-user nomarker"
              left
              toggle-class="d-flex align-items-center dropdown-user-link"
            >
              <template #button-content>
                <b-button
                  :variant="filters[5].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="filters[5].open=!filters[5].open"
                >
                  <span>{{ filters[5].title }}</span>
                  <div
                    v-if="filters[5].selected != null"
                    :class="`${filters[5].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25 rounded-pill fw-bold`"
                  >
                    {{ filters[5].selected.length ? filters[5].selected.length : '1' }}
                  </div>
                  <div
                    v-else
                    class="text-white py-25 rounded-pill fw-bold"
                  >.
                  </div>
                  <feather-icon
                    :icon="filters[5].open?'ChevronUpIcon':'ChevronDownIcon'"
                    class="ml-50"
                  />
                </b-button>
              </template>

              <div
                class="custom-search px-1 pt-1 cursor-pointer"
                @click="filters[5].selected=null"
              >
                <h6 class="text-primary">
                  -- No Filter --
                </h6>
              </div>
              <div
                class="custom-search px-1 pt-50"
              >
                <b-form-group
                  class="mb-0"
                >
                  <b-form-checkbox
                    :checked="filters[5].selected && filters[5].selected === 1"
                    @change="selectFilter(5, 'Single', 1)"
                  >
                    Yes
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <div
                class="custom-search px-1 pt-50"
              >
                <b-form-group
                  class="mb-0"
                >
                  <b-form-checkbox
                    :checked="filters[5].selected && filters[5].selected === -1"
                    @change="selectFilter(5, 'Single', -1)"
                  >
                    No
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </div>
      </div>
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="startupSearchTerm"
            class="d-inline-block mr-1"
            placeholder="Search Startups"
            type="text"
          />
        </b-form-group>
      </div>
      <vue-good-table
        :columns="startupColumns"
        :pagination-options="{
          enabled: true,
          perPage: startupPageLength
        }"
        :rows="startupRows"
        :search-options="{
          enabled: true,
          externalQuery: startupSearchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Status -->
          <span v-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                :to="{name: 'review-questions',
                      params: {pid:$route.params.id,
                               apid: selectedExerciseId,
                               aid: props.row.id,},
                      query: {readonly: 'true'}}"
                class="mr-50"
                size="sm"
                variant="outline-primary"
              > View Exercise </b-button>
              <b-button
                :to="{name: 'review-evaluations',params: {pid:$route.params.id,
                                                          apid: selectedExerciseId,
                                                          aid: props.row.id,}}"
                size="sm"
                class="mr-50"
                variant="outline-primary"
              >View Evaluations</b-button>
              <b-button
                size="sm"
                variant="outline-primary"
                @click="selectedRow = props.row.originalIndex; $bvModal.show('recommendationModal')"
              >Update</b-button>
            </span>
          </span>
          <!-- Column: Action -->
          <span
            v-else-if="props.column.field === 'title'"
            class="d-flex justify-content-between"
          >
            <span>
              {{ props.row.programs_applicantstable.users_organizationtable.title }}
            </span>
            <span>
              <b-badge
                v-if="filters[3].selected"
                class="mr-50"
                variant="light-primary"
              >
                {{
                  getVerdictName(parseFloat(props.row.programs_scoretables_aggregate.aggregate.avg.computed_verdict)
                    .toFixed(1))
                }}
              </b-badge>
              <b-badge
                v-if="filters[2].selected"
                class="mr-50"
                variant="primary"
              >
                {{
                  props.row.programs_scoretables_aggregate.aggregate.avg.participant_score !== 0.0 ? parseFloat(props.row.programs_scoretables_aggregate.aggregate.avg.participant_score)
                    .toFixed(1) : 'Unscored'
                }}
              </b-badge>
              <b-badge
                v-if="props.row.programs_startupresponsetables_aggregate.aggregate.count !== 0"
                class="mx-50"
                variant="light-primary"
              >
                {{ props.row.programs_startupresponsetables_aggregate.aggregate.count > 0 ? 'Partially Filled' : '' }}
              </b-badge>
            </span>
          </span>
          <span
            v-else-if="props.column.label === 'Verdict'"
            class="d-flex justify-content-between align-items-center"
          >
            {{ props.formattedRow[props.column.field] || '-' }}
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] || '-' }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <b-modal
      id="recommendationModal"
      ok-only
      ok-title="Update"
      title="Recommendations"
      @ok="updateStatus"
    >
      <div v-if="startupRows[selectedRow]">
        <b-form-group
          label="Recommendations"
        >
          <b-form-input
            v-model="startupRows[selectedRow].programs_assignmentscorerecords[0].recommendation"
            placeholder="Your recommendation for startup"
          />
        </b-form-group>
        <b-form-group
          label="Verdict"
        >
          <v-select
            v-model="startupRows[selectedRow].programs_assignmentscorerecords[0].verdict"
            :options="['Excellent','Good','Satisfactory','Unsatisfactory','Incomplete']"
            placeholder="Select from list"
          />
        </b-form-group>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BBadge,
  BButton,
  BCardHeader,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BNavbarNav,
  BNavItemDropdown,
  BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardHeader,
    vSelect,
    BFormInput,
    BBadge,
    BFormGroup,
    BFormCheckbox,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,

    BNavItemDropdown,
    BNavbarNav,
  },
  data() {
    return {
      mutationLoading: false,
      startupRows: [],
      filters: [
        {
          open: false,
          selected: null,
          title: 'Type',
        },
        {
          open: false,
          selected: null,
          title: 'Mentors',
        },
        {
          open: false,
          selected: null,
          title: 'Sort By Score',
        },
        {
          open: false,
          selected: null,
          title: 'Sort By Verdict',
        },
        {
          open: false,
          selected: 50,
          title: 'Weightage of Verdict :',
        },
        {
          open: false,
          selected: null,
          title: 'Is Assigned',
        },
      ],
      startupColumns: [
        {
          label: 'Title',
          field: 'programs_applicantstable.users_organizationtable.title',
          searchable: true,
        },
        {
          label: 'Recommendation',
          field(row) {
            if (row.programs_assignmentscorerecords.length === 0) {
              return '-'
            }
            return row.programs_assignmentscorerecords[0].recommendation
          },
          searchable: false,
        },
        {
          label: 'Verdict',
          field(row) {
            if (row.programs_assignmentscorerecords.length === 0) {
              return '-'
            }
            return row.programs_assignmentscorerecords[0].verdict
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      startupPageLength: 10,
      startupSearchTerm: '',
      pageLength: 10,
      selectedRow: 0,
      filterMentors: [],
      selectedExerciseId: this.$route.params.apid,
    }
  },
  methods: {
    getVerdictName(val) {
      if (val >= 8.5 && val <= 10) {
        return 'Excellent'
      }
      if (val >= 7 && val < 8.5) {
        return 'Good'
      }
      if (val >= 5 && val < 7) {
        return 'Satisfactory'
      }
      return 'Unsatisfactory'
    },
    statusVariant(status) {
      const statusColor = {
        /* eslint-disable key-spacing */
        enabled: 'light-primary',
        disabled: 'light-danger',
        draft: 'light-secondary',
        null: 'light-secondary',
        /* eslint-enable key-spacing */
      }
      return statusColor[status]
    },
    fetchSortScore() {
      this.filters[2].selected = !this.filters[2].selected
    },
    fetchSortVerdict() {
      this.filters[3].selected = !this.filters[3].selected
    },
    updateStatus() {
      this.mutationLoading = true
      if (
        this.startupRows?.[this.selectedRow]
          && this.startupRows[this.selectedRow].programs_assignmentscorerecords
          && this.startupRows[this.selectedRow].programs_assignmentscorerecords[0]
      ) {
        const mutationObject = {
          ...this.startupRows[this.selectedRow].programs_assignmentscorerecords[0],
        }
        // eslint-disable-next-line no-underscore-dangle
        this.$apollo.mutate(
          {
            mutation: gql`mutation MyMutation($object: programs_assignmentscorerecord_insert_input!) {
              insert_programs_assignmentscorerecord_one(object: $object, on_conflict: {constraint: programs_assignmentscorerecord_pkey, update_columns: [verdict, recommendation]}) {
                id
              }
            }`,
            variables: {
              object: mutationObject,
            },
            update: () => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Updated successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.mutationLoading = false
              this.$apollo.queries.startupRows.refetch()
            },
          },
        )
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'There was an error',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
            this.mutationLoading = false
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to update status: Missing data',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        this.mutationLoading = false
      }
    },
    selectFilter(index, mode, value) {
      if (mode === 'Single') {
        if (this.filters[index].selected === value) {
          this.filters[index].selected = null
        } else {
          this.filters[index].selected = value
        }
      } else if (mode === 'Multiple') {
        if (this.filters[index].selected !== null && this.filters[index].selected.indexOf(value) >= 0) {
          const temp = this.filters[index].selected
          temp.splice(temp.indexOf(value), 1)
          this.filters[index].selected = temp
          if (temp.length === 0) {
            this.filters[index].selected = null
          }
        } else {
          const temp = this.filters[index].selected ? this.filters[index].selected : []
          temp.push(value)
          this.filters[index].selected = temp
        }
      }
    },
    getMentorsList() {
      let string = ''
      if (this.filters[1].selected) {
        this.filters[1].selected.map(mentor => {
          if (string === '') {
            string = `"${mentor}"`
          } else {
            string = `${string}, "${mentor}"`
          }
          return 0
        })
      }
      return string
    },
    updateAssignmentStatus(status, item, col) {
      const update = status === 'enabled' ? 'disabled' : 'enabled'
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation  updateAssignmentStatus($item: Int!, $col: String!, $update: String!) {
      update_programs_assignmenttable_by_pk(pk_columns: {id: $item}, _set: {$col: $update}) {
      id
      }
      }`,
        variables: {
          item,
          col,
          update,
        },
        update: () => {
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    exercise: {
      query() {
        return gql`query ProgramsExercises($id: Int! , $apid: Int!) {
          programs_operationstable(order_by: {id: desc}, where: {program_id: {_eq: $id}, programs_assignmenttables:{id:{_eq:$apid}}}) {
            title
            id
            programs_assignmenttables {
              id
              title
              }
        }
        }
`
      },
      variables() {
        return {
          id: this.$route.params.id,
          apid: this.$route.params.apid,
        }
      },
      update: data => data.programs_operationstable[0].programs_assignmenttables[0],
    },
    startupRows: {
      query() {
        return gql`query startupRows{
      programs_startupparticipants(
      where: {
      _or: [
      {
         programs_startupresponsetables_aggregate: {
           count: {
            predicate: {_gt: 0},
           filter: {
           programs_assignmentquestionstable: {
             programs_assignmenttable: {id: {_eq: ${this.selectedExerciseId}}}
                     }
                  }
                 }
            }
         },
      {
         programs_startupassignmenttables: {assignment_id: {_eq: ${this.selectedExerciseId}}}
      }
      ],
      ${this.filters[1].selected ? `
      programs_scoretables_aggregate: {
         count: {
           predicate: {_eq: ${this.filters[1].selected.length}},
         filter: {
          computed_verdict: {_gt: "0"},
          partner_id: {_in: [${this.getMentorsList()}]}
            }
         }
      },
      ` : ''}
      ${this.filters[0].selected ? `
      programs_startupresponsetables_aggregate: {
         count: {
           predicate: {${this.filters[0].selected === 1 ? '_eq: 0' : '_gt: 0'}},
         filter: {
           is_filled: {_eq: false}
           }
         }
      },
      ` : ''}
      ${this.filters[5].selected && this.filters[5].selected === 1 ? `
      programs_startupassignmenttables: {assignment_id: {_eq: ${this.selectedExerciseId}}}
      ` : ''}
      }
      distinct_on: id
      ) {
      id
      programs_applicantstable {
         users_organizationtable {
            title
         }
       }
      programs_assignmentscorerecords(where: {assignment_id: {_eq: ${this.selectedExerciseId}}}) {
         id
         recommendation
         verdict
       }
      programs_startupresponsetables_aggregate(where: {is_filled: {_eq: false}}) {
      aggregate {
      count
       }
      }
      programs_startupassignmenttables(where: {assignment_id: {_eq: ${this.selectedExerciseId}}}) {
      id
      }
      programs_scoretables_aggregate(where: {
      ${this.filters[1].selected ? `
         partner_id: {_in: [${this.getMentorsList()}]},
          ` : ''}
          assignment_id: {_eq: ${this.selectedExerciseId}}
         }) {
        aggregate {
          avg {
             computed_verdict
             participant_score
            }
          }
        }
       }
      }`
      },
      update(data) {
        const rows = data.programs_startupparticipants
        rows.forEach(row => {
          if (!row.programs_assignmentscorerecords.length) {
            row.programs_assignmentscorerecords.push({
              assignment_id: this.$route.params.apid,
              participant_id: row.id,
              verdict: null,
              recommendation: null,
            })
          }
          if (!row.programs_scoretables_aggregate.aggregate.avg.participant_score) {
            rows[rows.indexOf(row)].programs_scoretables_aggregate.aggregate.avg = {
              participant_score: 0,
              computed_verdict: 0,
            }
          }
        })
        if (rows.length > 0 && rows[0].programs_scoretables_aggregate) {
          if (this.filters[2].selected && !this.filters[3].selected) {
            rows.sort((a, b) => b.programs_scoretables_aggregate.aggregate.avg.participant_score - a.programs_scoretables_aggregate.aggregate.avg.participant_score)
          }
          if (this.filters[3].selected && !this.filters[2].selected) {
            rows.sort((a, b) => b.programs_scoretables_aggregate.aggregate.avg.computed_verdict - a.programs_scoretables_aggregate.aggregate.avg.computed_verdict)
          }
          if (this.filters[3].selected && this.filters[2].selected) {
            rows.sort((a, b) => ((b.programs_scoretables_aggregate.aggregate.avg.computed_verdict * this.filters[4].selected) + (b.programs_scoretables_aggregate.aggregate.avg.participant_score * (100 - this.filters[4].selected))) - ((a.programs_scoretables_aggregate.aggregate.avg.computed_verdict * this.filters[4].selected) + (a.programs_scoretables_aggregate.aggregate.avg.participant_score * (100 - this.filters[4].selected))))
          }
        }
        return rows
      },
    },
    filterMentors: {
      query() {
        return gql`
        query mentors{
          programs_partnerstable(where: {programs_scoretables_aggregate: {count: {predicate: {_gt: 0}, filter: {assignment_id: {_eq: ${this.$route.params.id}}}}}}) {
            id
            users_organizationtable {
              title
            }
            users_customuser {
              full_name
            }
          }
        }`
      },
      update: data => data.programs_partnerstable,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style>
.dropdown-toggle::after {
  display: none;
}

.filters-box {
  border-top: solid 1px #d3d3d3;
  border-bottom: solid 1px #d3d3d3;
}

.fl-col-1 {
  width: fit-content;
  padding-right: 1rem;
  display: flex;
  align-items: center;
}

.fl-col-2 {
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-left: solid 1px #d3d3d3;
  border-right: solid 1px #d3d3d3;
  display: flex;
  flex-wrap: wrap;
  /* overflow-x: auto;
  overflow-y: visible; */
}

.fl-col-2::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.fl-col-2::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fl-col-2::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.filter-holder {
  display: flex;
}

.fl-col-3 {
  width: fit-content;
  padding-left: 1rem;
  display: flex;
  align-items: center;
}
</style>
